.Wrapper {
    display: flex;
    justify-content: center;
    gap: 8px;
    max-width: 250px;
}

.Wrapper div {
    width: auto;
}

.Wrapper input {
    padding: 0;
    font-size: 20px;
    height: 44px;
    width: 35px !important;
    text-align: center;
    border: 1px solid var(--lawmonitor-gray-300);
    border-radius: 9px;
}

.Wrapper > div:nth-child(4) {
    margin-left: 12px;
}

.Wrapper input:focus {
    outline: 1px solid var(--lawmonitor-blue-300);
}
